
.navbar-container {
    width: 100%;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo-container .logo {
    /* height: 50px; */
    width: 200px;
  }
  
  .burger-menu {
    font-size: 24px;
    cursor: pointer;
    display: none;
  }
  .des_nav_items {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: 0;
  }
  .nav-individual a:hover{
background-color: #8733d1d9;
color: #19945E;

  }
  .des_nav_items li {
    list-style: none;
    font-family: 'Mohave';
    font-size: 23px;
    font-weight: 600;
    line-height: 36.22px;
    text-align: center;
    color: #19945E;
    position: relative; /* Required for the ::after pseudo-element */
    cursor: pointer;
  }
  
  .des_nav_items li::after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    bottom: -5px;
    left: 0;
    background-color: #19945E; /* Line color */
    transition: width 0.4s ease-out; /* Transition for smooth effect */
  }
  
  .des_nav_items li:hover::after {
    width: 100%; /* Full width of the item on hover */
  }
  .mbl_nav_items{
 padding-top: 50px;

  }
  .mbl_nav_items li{
 padding-top: 10px;
 list-style: none;
 font-family: 'Mohave';
 font-size: 23px;
 font-weight: 600;
 line-height: 36.22px;
 color: #19945E;

  }

  
  .nav-items {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 70%;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 10000;
  }
  
  .nav-items.open {
    transform: translateX(0);
  }
  
  .nav-items ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .nav-social-icons {
    display: flex;
    gap: 20px;
  }
  
  .nav-individual a {
    display: flex;
    align-items: center;
    text-decoration: none;
    text-decoration: none;
    font-family: Mohave;
font-size: 18px;
font-weight: 500;
line-height: 29.25px;
text-align: center;
background-color: #19945E;
color: white;
border-radius: 6px;
padding: 4px 10px;
transition: 0.9s;
  }
  
  .nav-individual a svg {
    margin-right: 5px;
  }
  
  .close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }
  

  .banner_container{
    background: linear-gradient(259.66deg, #8633D1 0%, #7686ED 78.38%);
    padding: 20px 180px;
  }
  .banner_content{
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .banner_content h1{
    font-family: Mohave;
    font-size: 80px;
    font-weight: 700;
    line-height: 80px;
    text-align: left;
    color: white;
    padding-bottom: 15px;
  }
  .banner_content p{
    font-family: Josefin Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: white;
    padding-bottom: 15px;
    
  }
  .banner_btns{
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 40px;
  }
  .banner_btns a{
    text-decoration: none;
    border: 1.5px solid white;
    opacity: 0px;
    font-family: Josefin Sans;
    font-size: 22px;
    font-style: italic;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
color: #fff;  
padding: 8px 15px 6px 15px ; 
border-radius: 3px; 
text-transform: uppercase;    
transition: 1.6s;
  }
  .banner_btns a:hover{
background-color:white;
color: #8633D1;
  }


  .location_img_container {
    display: grid;
    gap: 60px; /* Space between items */
    grid-template-columns: repeat(4, 1fr); /* 4 items per row in desktop view */
}
.popular_section{
    background: white !important;
    border-radius: 6px;
    z-index: 100 !important;
    position: relative;
    bottom: 60px;
    padding: 50px 30px;
    box-shadow: 0px 3px 9px 0px #4f4f4f66;
}
.popular_section h2{
    font-family: Mohave;
font-size: 32px;
font-weight: 600;
line-height: 20px;
text-align: center;
color: #19945E;
padding-bottom: 55px;
}
.single_location {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.single_location img{
height: 72px;
width: 72px;
border-radius: 50%;
object-fit: cover;
}
.location_name{
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: start;
}
.location_name p{
    padding-top: 14px;
    font-family: Josefin Sans;
font-size: 24px;
font-weight: 500;
text-align: left;

}
.location_name span{
    font-family: Josefin Sans;
font-size: 18px;
font-weight: 300;
line-height: 18px;
text-align: left;
color: #222222;

}

.heading_of_Sub{
    font-family: Mohave;
font-size: 32px;
font-weight: 600;
line-height: 20px;
text-align: left;
border-left: 5px solid #8633D1;
}
.g-5{
    gap: 30px;
}


/* Footer */
.footer-all{
height: 530px;
border-radius: 12px 12px 0px 0px;
background: linear-gradient(260deg, #8633D1 0%, #7686ED 78.38%);
}
.f-head h4{
    padding-bottom: 40px;
    color: #FAFAFA;
    leading-trim: both;
    text-edge: cap;
    font-family: Mohave;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.f-head p{
    color: #FFF;
text-align: justify;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
}

.quick-navigation li{
    list-style-type: none;
    margin-bottom: 20px;
}
.quick-navigation h4{
    padding-bottom: 40px;
    color: #FAFAFA;
leading-trim: both;
text-edge: cap;
font-family: Mohave;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.quick-navigation a{
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: "Josefin Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-decoration-line: underline;
    text-transform: uppercase;
}
.custom-hr{
    background: #FFF;
    height: 2px;
}
.copy-rights {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding: 10px;
  }
  
  .copy-rights h3,
  .copy-rights h4 {
    margin: 0; 
    color: #FFF;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .footer-all{
    padding: 55px;
  }
  .contact-foot h4{
    padding-bottom: 40px;
    color: #FAFAFA;
leading-trim: both;
text-edge: cap;
font-family: Mohave;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .mail{
    display: flex;
    /* align-items: center; */
    margin-bottom: 20px;
    gap: 24px;

  }
  .mail h5{
    color: #FFF;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  .mail p,a{
    color: #FFF;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration: none;
  }
  .mail-des h6{
    color: #FFF;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22px; /* 137.5% */
  }
  .social {
    display: flex;
    gap: 10px; 
  }
  
  .social-icon {
    width: 28px;
    height: 28px;
    background-color: #FDFDFD;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px; 
    color: #000; 
    padding: 5px;
  }

  /* homepage */
  .get-a-quote{
    border-radius: 12px;
background: linear-gradient(260deg, #8633D1 0%, #7686ED 78.38%);

justify-content: space-between;
align-items: center;
padding: 34px 50px 33px 50px;
align-items: center;
  }
  .get-a-quote p{
    color: #FAFAFA;
leading-trim: both;
text-edge: cap;
font-family: "Josefin Sans";
font-size: 24px;
font-style: italic;
font-weight: 700;
line-height: 36px; /* 150% */
letter-spacing: -0.48px;
  }
  .get-a-quote button{


border-radius: 4px;
border: 2px solid #FAFAFA;
color: #FAFAFA;
margin-top: 10px;
font-family: "Josefin Sans";
font-size: 24px;
font-style: italic;
font-weight: 700;

background-color: transparent;
  }



  @media screen and (max-width: 1131px) {
    .des_nav_items li{
        font-size: 16px !important;
    }
    .nav-individual a{
        font-size: 13px !important;

    }
    .banner_content h1{
        font-size: 37px;
    }
    .banner_content p{
        font-size: 17px;
    }

  }
  @media screen and (max-width: 980px) {
    .location_img_container {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row in mobile view */
        gap: 26px;
    }
    
    .des_nav_items li{
        font-size: 16px !important;
    }
    .nav-individual a{
        font-size: 13px !important;

    }
    .banner_content h1{
        margin-top: 20px;
        font-size: 37px;
        line-height: 53px;
    }
    .banner_content p{
        font-size: 14px;
    }
    .location_name p{
        font-size: 14px;
    }
    .location_name span{
        font-size: 12px;
    
    }
    .banner_btns a {
        text-decoration: none;
        border: 1.5px solid white;
        opacity: 0px;
        font-family: Josefin Sans;
        font-size: 12px;
        font-style: italic;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: #fff;
        padding: 4px 6px 4px 6px;
        border-radius: 3px;
        text-transform: uppercase;
        transition: 1.6s;
        text-align: center;
    }

  }
  @media screen and (max-width: 768px) {
    .location_img_container {
        grid-template-columns: repeat(2, 1fr); /* 2 items per row in mobile view */
        gap: 26px;
    }
    .burger-menu {
        display: block;
        position: absolute;
        left: -13px;
        top: 10px;
    }
    .single_location img {
height: 56px;
width: 56px;

    }
    .single_location {

gap:10px;
    }
  
    .logo-container {
     margin: 0 auto;
    }
  
    .nav-social-icons {
      flex-direction: column;
      gap: 10px;
    }
    .des_nav_items{
        display: none !important;
    }
    .nav-social-icons{
        display: none !important;

    }
    .banner_content h1 {
        font-size: 46px;
        line-height: 62px;
        text-align: center;
        margin-top: 26px;
    }

    .banner_content p {
        font-size: 13px;
        text-align: justify;
        width: 80%;
        margin: 0px auto;
  }
  .banner_btns a {
    text-decoration: none;
    border: 1.5px solid white;
    opacity: 0px;
    font-family: Josefin Sans;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #fff;
    padding: 4px 6px 4px 6px;
    border-radius: 3px;
    text-transform: uppercase;
    transition: 1.6s;
    text-align: center;
}
.banner_btns{
    justify-content: center;
}
.popular_section h2 {
    font-family: Mohave;
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    text-align: center;
    color: #19945E;
    padding-bottom: 55px;
}
.location_name p{
    font-size: 14px;
}
.location_name span{
    font-size: 12px;

}
.nav-items ul{
    padding-left: 0px;
}
.mbl_nav_items li{
    padding-left: 20px;
}
.nav-individual a{
    text-align: center;
}
  }
.carousel__package-title{
font-size: 18px;
}
