:root {
  --primary-color: #FAFAFA;
  --secondary-color: #19945E;
  --font-family: "Jost";
  --heading-font-size: 44px;
  --heading-font-weight: 700;
  --social-font-size: 24px;
  --social-font-weight: 600;
}
p{
  color: #606969;
text-align: justify;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 26px; /* 162.5% */
}

h1 {
  color: var(--primary-color);
  text-align: center;
  font-family: var(--font-family);
  font-size: var(--heading-font-size);
  font-style: normal;
  font-weight: var(--heading-font-weight);
}
h2{
  color: #222;
font-family: "Mohave";
font-size: 32px;
font-style: normal;
font-weight: 500;
 /* 62.5% */
}
h5{
  color: #222;
font-family: "Mohave";
font-size: 20px;
font-weight: 500;
/* 100% */
}
.carousel-caption {
  position: absolute;
  bottom: 17rem;
  left: 51%;
  transform: translateX(-50%);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--primary-color);
  text-align: center;
  width: 70%;
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 35%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.carousel-indicators .active {
  background-color: var(--primary-color);
  opacity: 1;
}

.btn-header {
  border-radius: 8px !important;
  background: #19945E;
  padding: 10px 20px;
  color: var(--primary-color);
  border: 0;
}

.Social-media {
  color: var(--primary-color);
  text-align: center;
  font-size: var(--social-font-size);
  font-style: normal;
  font-weight: var(--social-font-weight);
}

.Social-media a {
  text-decoration: none !important;
  color: var(--primary-color);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  border-radius: 50%;
  border: 2px solid white;
}
.count{
  color: #222;
  font-family: "Josefin Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;

}
h5{
  color: #222;
  font-family: "Mohave";
  font-size: 20px;
  font-weight: 400;
}
.Section-time{
  background-color: #E7FCF0;
}
.point-package{
  position: relative;
  bottom: 2px;
}
.text-warning{
  color: #F18B16 !important 

}
.download-text {
  color: #222;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 500;
margin-bottom: 1px !important;
text-align: start;
}
a{
  text-decoration: none;
  color: #222;
}
.my-80{
  margin-top: 80px;
  margin-bottom: 80px;
}
.bg-info{
background-color: #19945E !important;
border-radius: 12px;
}
.package-places{
  color: #49206D;
  text-align: center;
  font-family: "Mohave";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height :normal;
   /* 100% */
}
.Packages-prices{
color: #49206D;
text-align: center;
font-family: "Mohave";
font-size: 24px;
overflow: hidden;
font-weight: 600;


/* 83.333% */
}
.text-offer{
  color: #49206D;
text-align: center;
overflow: hidden;
font-family: "Mohave";
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 50px; /* 104.167% */
}
.person-contant{
color: #49206D;
text-align: center;
font-family: "Mohave";
font-size: 16px;
font-style: normal;
font-weight: 500;
 /* 125% */
}
.package-border{
  border-radius: 16px;
border: 0.4px solid #19945E;
background: #E7FCF0;  
padding: 20px;
}
.custom-border-end {
  border-right: 1px solid #19945E; /* Change #4a90e2 to any color you prefer */
}
.itinerary-container {
  width: 100%;
  padding: 20px;
}


.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.itinerary-button {
  padding: 10px 15px;
  background-color: #f4fbff;
  border: 1px solid #b3e0ff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.itinerary-button:hover {
  background-color: #E7FCF0;
}

.description {
  font-size: 14px;
  margin-top: 15px;
}

.image-group {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.itinerary-image {
  width: 200px;
  height: auto;
  border-radius: 8px;
}
.btn-Itenary{
border-radius: 4px;
background: #EEE;
padding:7px 20px;
border: 0px;
color: #19945E;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 400;
}

.accordion-button {
  background-color: #Fff !important;
  color: #FAFAFA;
  font-weight: bold;
  padding: 15px;
  cursor: pointer;
}

.accordion-button:focus {
  outline: none;
  box-shadow: none;
}





.accordion-item {
  margin-bottom: 15px;
}
/* Add this to your stylesheet */
.border-none {
  border: none !important;
  border-bottom: 1px solid #D3D3D3 !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.custom-list-item{
  color: #606969;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  text-align: justify;

  margin-bottom:14px;
}
 /* 100% */

.text-purple{
  color: #6A3698;
text-align: center;
font-family:"Mohave";
font-size: 32px;
font-style: normal;
font-weight: 600;

}
/* footer.css */

footer {
 background-image: url(Assets/images/footer.png);
 background-repeat: no-repeat;
 background-size: cover;
 padding-bottom: 200px !important;
 padding: 40px 0;
}

footer h4,
footer h5 {
  color: white;
}

footer p {
  color: white;
}

footer ul {
  list-style-type: none;
  padding: 0;
}

footer ul li {
  color: white;
  margin-bottom: 8px;
  color: #FFF;
  font-family: "Josefin Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-underline-offset: 4px;
  text-decoration-line: underline;
}



footer .social-icons {
  margin-top: 1px;
}

footer .social-icons svg {
  color: white;
  margin-right: 10px;
  cursor: pointer;
}

footer .social-icons svg:hover {
  color: #ddd;
}

footer .contact-info p {
  margin: 0;
  color: white;
}


.BookOneTour{
color: #FAFAFA;
font-family: "Mohave";
font-size: 40px;
font-style: normal;
font-weight: 600;

}
.QUICK-links{
  color: #FAFAFA;
font-family: "Mohave";
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.img-social{
  width: 70%;
}
.border-Frequently{
  background: #E7FCF0 !important;
}
.py-80{
  padding-top: 80px;
  padding-bottom: 80px;
}
h3{
  color: #222;
font-family: "Josefin Sans";
font-size: 20px;
font-style: normal;
font-weight: 500;

}
*{
  overflow-x: hidden;
}

/* Style for carousel control buttons */


/* Override the default background image to customize the arrow */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #222;
 /* Black background for the control icon */
  background-size: 70%, 70%;
  border-radius: 50%; /* Optional: make the background a circle */
}

/* Custom arrow icons in white */

/* Adjust the arrow positioning */
.carousel-control-prev-icon::after {
  transform: rotate(-135deg); /* Rotate to make a left arrow */
  margin-left: 4px; /* Adjust positioning */
}

.carousel-control-next-icon::after {
  transform: rotate(45deg); /* Rotate to make a right arrow */
  margin-right: 4px; /* Adjust positioning */
}
.avatar {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  object-fit: cover;
   margin-right: 10px;
}
.card-title{
color: #222;
font-family: "Josefin Sans";
font-size: 16px;
font-style: normal;
font-weight: 500;

}
.card-subtitle{
color: #606969;
text-align: justify;
font-family: "Josefin Sans";
font-size: 12px;
font-style: normal;
font-weight: 400;

}
.card{
  border-radius: 12px !important;
background: #FFF !important;
box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.20) !important;
}

.carousel__subtitle {
  margin-bottom: 24px;
}

.carousel__wrapper {
  display: flex;
  align-items: center;
}



.carousel__button--prev {
  margin-right: 8px; border: 1px solid #222222;
  color:#000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.carousel__button--next {
  margin-left: 8px;
  border: 1px solid #222222;
  color:#000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.carousel__cards {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 14px;
}

.carousel__card {
  margin: 0 8px;

  box-shadow: 0px 2px 10px rgb(184, 176, 176) ;
  border-radius: 8px;
  border-radius: 12px;
  background: #FFF;
  overflow: hidden;
}

.carousel__image {
  width: 100%;
  padding-top: 14px;
  padding-left: 14px;
  padding-right: 14px;
  object-fit: cover;
}

.carousel__content {
  padding: 16px;
}

.carousel__package-title {
  margin-bottom: 8px;
}

.carousel__description {
color: #5A5A5A;
}

.carousel__price {
  margin-top: 12px;
}

.Person-rate{
color:  #5A5A5A;
font-family: "Poppins";
font-size: 10px !important;
font-style: normal;
font-weight: 400;
}

h1 , h2, h3, h4 ,h5, h6 {
overflow: hidden;
}

.Close-items{
  color: rgba(236, 60, 60, 1);
 }
.count{
  text-align: center;
}
 .Open-items{
   color: #1CC671;
 }
 
 .form-head{
color: #FAFAFA;
font-family: "Josefin Sans";
font-size: 30px ;
font-style: normal;
font-weight: 700;
letter-spacing: 1.2px;



}
.my-100{
  margin-bottom: 100px;
  margin-top: 100px;
}
/* Hide the popup form by default */
.form-popup {
  display: none;

}

/* Show the popup form only on mobile screens (max-width: 576px) */

.carousel__cards {
  overflow: hidden;
  width: 100%;
  padding: 20px;
}


/* Default size for larger screens */
.img-social {
  width: 40px; /* Adjust this as needed */
  height: auto;
}
.mx-10{
  margin-left: 20px;
  margin-right: 20px;
}

/* Adjust sizes for smaller screens */


@media (max-width: 576px) {
  .img-social {
    width: 30px; 
   /* Even smaller size for extra-small devices */
  }
  h1{
    font-size: 30px;
  }
  h2{
    font-size: 24px;
  }
  h3{
    font-size: 18px;
  }
  .download-text{
    text-align: 18px;
    margin-top: 20px;
    text-align: center;
  }
  p{
    font-size: 14px;
  }
  .download-pdf
  {
    text-align: 18px;
    margin-top: 20px;
  }
  .custom-border-end {
 /* or any specific value */
    border-bottom: 1px solid #19945E !important;

    border: none;
}
.carousel-item {
  flex-direction: row;
}
.person-contant{
 padding-bottom: 10px;
}

  .package-places{
    font-size: 18px;
  }
  .insta-btn {
    position: fixed;
    top: 90%;
    left: 10px;
    width:80px;
    border-radius: 50%;
    background-color:#19945E;  /* Customize as needed */
}
.custom-list-item{
   font-size: 14px;
}
.person-contant





.insta-btn i, .whatsapp-btn i {
    font-size: 24px;
}
.mx-10{
  margin-left: 0px;
  margin-right: 0px;
}
.form-head{
  font-size: 14px;
}

.carousel-item {
  flex-direction: column;
  align-items: center;
}

}
h5{
  font-size: 18px;
}
.person-contant{
  overflow: hidden !important;

 
}
.download-pdffile{
  text-decoration: underline;
  color: #F18B16;


text-align: center;

font-family: "Mohave";
font-size: 16px;
font-style: normal;
text-underline-offset: 4px;
text-decoration-line: underline;
}

.btn-header {
  display: flex;
  align-items: center;
}
.custom-btn {
  background-color: #FAFAFA;
  color:#19945E; 
  border: 1px solid #d4d4d4; 
padding: 8px;
  border-radius: 5px;

  font-family: "Josefin Sans";
  font-size: 16px;

  font-weight: 400;
  transition: all 0.3s ease;
}

.custom-btn:hover {
  background-color: #cfe3d6; /* Slightly darker green on hover */
  border-color: #b5c7ba;
}

@media (max-width: 768px) {
  .custom-btn {
    width: 100%; /* Full-width on smaller devices */
    margin-bottom: 10px;
  }
}



.btn-warning{

  background: #F18B16 !important;
}
.btn-warning:hover{
 color: #FAFAFA !important;
  background: #F18B16 !important;
}
.green-backgroundImage{
  background-image: url("Assets/images/green.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
/* Adjust the carousel for mobile devices */



/* Center the text and adjust size for mobile devices */
.mobile-text-center {
  text-align: center;
  font-size: 14px; /* Adjust as needed */
}

/* Ensure button is centered on mobile */



.button-custom {
  display: inline-block;
  margin: 5px;
}

.custom-btn {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
 
  cursor: pointer;
  text-align: center;
}

/* Media query for mobile screens */
@media (max-width: 767.98px) {
  .button-custom {
    width: 44%; /* Two buttons per row */
  }

}
/* Media query for 1024px screens */
@media (min-width: 1024px) and (max-width: 1220px) {
  .download-text{
    font-size: 12px;
  }
  .download-pdf{
    font-size: 10px;
  }
  .package-places{
  font-size: 16px;
  }
  
}
.viewpoints-icons {
  list-style-type: none;
  display: flex;
  flex-direction: row; 
 text-align: center;
  align-items: center; 
}

.viewpoints-icons li {

  padding: 10px; /* Adds space between icons */
  
}

.viewpoints-icons img {
  width: 30px; /* Adjust as needed */
  height: auto;
}
.icon-text{
  font-size: 6.85px;
  font-weight: 700;
  font-weight: "Josefin Sans";
  color: #222222;
  
}

.des_nav_items li{
  overflow: hidden;

}